// @flow
import React from 'react';
import Head from 'next/head';

import useSeoData from '../../hooks/Page/useSeoData';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import useTagId from '../../hooks/Page/useTagId';
import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';
import useAuthorId from '../../hooks/Page/useAuthorId';
import { buildUrl, } from '../../utils/buildImgURLs';

const { isTM, isHDC, } = checkSiteFromConfig();

function SectionAndHomepageSEO() {
  const seoData = useSeoData();
  const tagId = useTagId();
  const authorId = useAuthorId();
  const breadcrumbs = useBreadcrumbsData();

  const lineage = breadcrumbs || [];

  const {
    title,
    description,
    canonicalUrl,
    metaDescription,
    metaTitle,
    ogTitle,
    ogDescription,
    ogImage,
    obTitle,
    robots,
    alternates,
    alternate,
  } = seoData || {};
  const { files, contentId, } = ogImage || {};
  const ogImageUrl = ogImage && Array.isArray(files) && files[0]
    ? buildUrl(
      contentId,
      files[0],
      {
        height: '630',
        width: '1200',
        quality: 'auto',
        // headline aspect is used because its 16/9 ratio, close to facebook's 1.91/1 ratio
        aspect: files[0].aspects && ('headline' in files[0].aspects)
          ? 'headline' : 'full',
      }
    )
    : '';
  const tagAuthorName = lineage && Array.isArray(lineage) && lineage?.[0]?.name;
  const headTitle = metaTitle || title || ogTitle || obTitle;
  return (
    <Head>
      {headTitle ? <title>{headTitle}</title> : null}
      <meta
        property="article:publisher"
        content={`https://www.facebook.com/${isTM ? 'TheMarkerOnline' : isHDC ? 'haaretzcom' : 'haaretz'}`}
        key="article:publisher"
      />
      {ogImageUrl ? <meta property="og:image" content={ogImageUrl} key="og:image" /> : null }
      <meta property="fb:pages" content="109551402519" key="fb:pages" />
      <meta name="description" content={((authorId || tagId) && tagAuthorName) || metaDescription || description || ogDescription} key="description" />
      <link rel="canonical" href={canonicalUrl} />
      {robots ? <meta name="robots" content={robots} /> : null}
      {alternate
        ? <link rel="alternate" type="application/rss+xml" title={Array.isArray(alternates) && alternates[0]?.title} href={alternate} /> : null}
      {(authorId || tagId) && Array.isArray(alternates) && alternates.length > 0
        ? <link rel="alternate" type={alternates[0].contentType} title={alternates[0].title} href={alternates[0].url} /> : null}
    </Head>
  );
}

export default SectionAndHomepageSEO;
